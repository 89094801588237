.description h1 {
  font-size: 16px;
}
/* Photo and description section start */
@media screen and (min-width: 667px) and (max-width: 2400px) {
  .photoAndDescription {
    display: flex;
    flex-direction: row;
  }
}

#soundtrackPhoto {
  width: 60%;
  margin-top: 40px;
} 

.description h1 {
  padding: 25px 5px 25px 25px;
}

@media screen and (min-width: 1601px) and (max-width: 2400px) {
  .photoAndDescription {
    margin-top: 40px;
  }

  .photo {
    width: 40%;
  }
  #soundtrackPhoto {
    width: 60%;
    margin-top: 0px;
  }
  .description {
    width: 60%;
    display: flex;
    align-items: center;
  }
  .description h1 {
    padding: 120px 5px 120px 120px;
  }
}

@media screen and (min-width: 667px) and (max-width: 1600px) {
  .photo {
    width: 40%;
  }
  #soundtrackPhoto {
    width: 65%;
    margin-top: 0px;
  }
  .description {
    width: 60%;
    display: flex;
    align-items: center;
  }
  .description h1 {
    padding: 45px 5px 45px 45px;
  }
}

@media screen and (min-width: 1601px) and (max-width: 2400px) {
  .photoAndDescription {
    margin-top: 40px;
  }
  #soundtrackPhoto {
    width: 55%;
  }
  .description h1 {
    margin: 0px 5px 0px 260px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .photoAndDescription {
    margin-top: 40px;
  }
  #soundtrackPhoto {
    width: 55%;
  }
  .description h1 {
    margin: 0px 5px 0px 120px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .photoAndDescription {
    margin-top: 40px;
  }
  .description p {
    padding: 60px 5px 60px 60px;
  }
}

@media screen and (min-width: 789px) and (max-width: 1600px) {
  .soundtracksPage {
    padding: 20px 60px;
  }
}
