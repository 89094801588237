#blanket {
  /* position: absolute; */
  /* top: 440px; */
  margin-left: 0px;
  height: 120vh;
  width: 1300px;
  /*size: cover;*/
}

#firstImage {
  position: absolute;
  top: 440px;
  margin-left: 0px;
  width: 300px;
}

#secondImage {
  position: absolute;
  top: 320px;
  width: 220px;
  margin-left: 80px;
}

#thirdImage {
  position: absolute;
  top: 300px;
  width: 360px;
  margin-left: 70px;
}

#fourthImage {
  position: absolute;
  top: 220px;
  width: 280px;
  margin-left: 220px;
}

#fifthImage {
  position: absolute;
  top: 280px;
  margin-left: 110px;
  width: 180px;
}

#sixthImage {
  position: absolute;
  top: -10px;
  width: 320px;
  margin-left: 480px;
}

#aboutprojectImage {
  position: absolute;
  top: -130px;
  width: 340px;
}

/* RESPONSIVENESS */
@media screen and (min-width: 1601px) {
}

@media screen and (min-width: 1025px) and (max-width: 1600px) {
  #firstImage:hover {
    content: url("../images/workshops_titleResized.png");
  }

  #secondImage:hover {
    content: url("../images/moreideastitle.png");
  }

  #thirdImage:hover {
    content: url("../images/performancesforobjects_titleResized.png");
  }

  #fourthImage:hover {
    content: url("../images/aboutthecreator_titleResized.png");
  }

  #fifthImage:hover {
    content: url("../images/soundtracksforsofas_titleResized.png");
  }

  #sixthImage:hover {
    content: url("../images/contacttheartistscandeliertitle.png");
  }
}

@media screen and (min-width: 220px) and (max-width: 1024px) {
  #firstImage {
    content: url("../images/workshops_titleResized.png");
  }

  #secondImage {
    content: url("../images/moreideastitle.png");
  }

  #thirdImage {
    content: url("../images/performancesforobjects_titleResized.png");
  }

  #fourthImage {
    content: url("../images/aboutthecreator_titleResized.png");
  }

  #fifthImage {
    content: url("../images/soundtracksforsofas_titleResized.png");
  }

  #sixthImage {
    content: url("../images/contacttheartistscandeliertitle.png");
  }

  /* on hover */
  #firstImage:hover {
    content: url("../images/workshopsfinalResized.png");
  }

  #secondImage:hover {
    content: url("../images/contacttheartistsfinal.png");
  }

  #thirdImage:hover {
    content: url("../images/PerformancesforObjectsfinalResized.png");
  }

  #fourthImage:hover {
    content: url("../images/aboutthecreatorfinalResized.png");
  }

  #fifthImage:hover {
    content: url("../images/Soundtrackforsofafinal.png");
  }

  #sixthImage:hover {
    content: url("../images/lampResized.png");
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #firstImage {
    width: 270px;
    top: 500px;
  }

  #secondImage {
    width: 190px;
    top: 400px;
  }

  #thirdImage {
    width: 330px;
    width: 270px;
    top: 400px;
  }

  #fourthImage {
    width: 230px;
    top: 260px;
  }

  #fifthImage {
    width: 150px;
  }

  #sixthImage {
    width: 280px;
    margin-left: 400px;
  }
}

@media screen and (min-height: 1024px) and (max-height: 1024px) {
    #fifthImage {
    top: 500px !important;
  }

  #fourthImage {
    top: 490px !important;
  }

  #thirdImage {
    top: 690px !important;
  }

  #secondImage {
    top: 600px !important;
  }

    #firstImage {
    top: 760px !important;
  }
}

@media screen and (min-height: 1366px) and (max-height: 1366px) {
  #firstImage {
    width: 370px;
    top: 1100px;
  }

  #secondImage {
    width: 290px;
    top: 890px;
    margin-left: 50px;
  }

  #thirdImage {
    width: 410px;
    top: 980px;
    margin-left: -60px;
  }

  #fourthImage {
    width: 330px;
    top: 630px;
    margin-left: 80px;
  }

  #fifthImage {
    width: 260px;
    top: 630px
  }

  #sixthImage {
    width: 380px;
    margin-left: 350px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #firstImage {
    top: 760px;
    margin-left: 20px;
    width: 240px;
  }

  #secondImage {
    top: 590px;
    width: 170px;
    margin-left: 60px;
  }

  #thirdImage {
    top: 660px;
    width: 240px;
    margin-left: 0px;
  }

  #fourthImage {
    top: 420px;
    width: 220px;
    margin-left: 80px;
  }

  #fifthImage {
    top: 450px;
    width: 160px;
    margin-left: 120px;
  }

  #sixthImage {
    top: 0px;
    width: 220px;
    margin-left: 300px;
  }
}

@media screen and (min-height: 736px) and (max-height: 736px) {
  #fifthImage {
    top: 350px !important;
  }

  #fourthImage {
    top: 360px !important;
  }

  #thirdImage {
    top: 560px !important;
  }

  #secondImage {
    top: 450px !important;
  }

    #firstImage {
    top: 610px !important;
  }
}

@media screen and (min-width: 734px) and (max-width: 767px) {
  #firstImage {
    top: 260px;
    margin-left: 20px;
    width: 200px;
  }

  #secondImage {
    top: 190px;
    width: 140px;
    margin-left: 60px;
  }

  #thirdImage {
    top: 200px;
    width: 170px;
  }

  #fourthImage {
    top: 110px;
    width: 180px;
    margin-left: 110px;
  }

  #fifthImage {
    top: 120px;
    width: 120px;
    margin-left: 80px;
  }

  #sixthImage {
    top: 0px;
    width: 140px;
    margin-left: 300px;
  }
}

@media screen and (min-width: 684px) and (max-width: 733px) {
  #firstImage {
    top: 280px;
    margin-left: 20px;
    width: 200px;
  }

  #secondImage {
    top: 200px;
    width: 140px;
    margin-left: 40px;
  }

  #thirdImage {
    top: 230px;
    width: 190px;
    margin-left: 40px;
  }

  #fourthImage {
    top: 110px;
    width: 170px;
    margin-left: 100px;
  }

  #fifthImage {
    top: 120px;
    width: 130px;
    margin-left: 60px;
  }

  #sixthImage {
    top: 0px;
    width: 140px;
    margin-left: 280px;
  }
}

@media screen and (min-width: 667px) and (max-width: 683px) {
  #firstImage {
    top: 230px;
    margin-left: 20px;
    width: 180px;
  }

  #secondImage {
    top: 160px;
    width: 130px;
    margin-left: 40px;
  }

  #thirdImage {
    top: 180px;
    width: 150px;
  }

  #fourthImage {
    top: 90px;
    width: 160px;
    margin-left: 80px;
  }

  #fifthImage {
    top: 90px;
    width: 120px;
    margin-left: 60px;
  }

  #sixthImage {
    top: 0px;
    width: 130px;
    margin-left: 280px;
  }
}

@media screen and (min-width: 412px) and (max-width: 666px) {
  #firstImage {
    top: 560px;
    width: 180px;
  }

  #secondImage {
    top: 410px;
    width: 135px;
    margin-left: 0px;
  }

  #thirdImage {
    top: 490px;
    width: 190px;
    margin-left: -60px;
  }

  #fourthImage {
    top: 310px;
    margin-left: 5px;
    width: 160px;
  }

  #fifthImage {
    top: 290px;
    margin-left: 70px;
    width: 130px;
  }

  #sixthImage {
    top: -5px;
    width: 200px;
    margin-left: 100px;
  }
}

@media screen and (min-height: 812px) and (max-height: 812px) {
  #firstImage {
    top: 680px !important;
  }

  #secondImage {
    top: 520px !important;
  }

  #thirdImage {
    top: 620px !important;
  }

  #fourthImage {
    top: 420px !important;
  }

  #fifthImage {
    top: 430px !important;
  }
}

@media screen and (min-height: 800px) and (max-height: 800px) {
    #fifthImage {
    top: 350px !important;
  }

  #fourthImage {
    top: 340px !important;
  }

  #thirdImage {
    top: 490px !important;
  }

  #secondImage {
    top: 450px !important;
  }

    #firstImage {
    top: 610px !important;
  }
}

@media screen and (min-height: 720px) and (max-height: 720px) {
  #firstImage {
    top: 560px;
    width: 180px;
  }

  #secondImage {
    top: 430px;
    width: 135px;
    margin-left: 40px;
  }

  #thirdImage {
    top: 500px;
    width: 190px;
    margin-left: -20px;
  }

  #fourthImage {
    top: 340px;
    margin-left: 20px;
    width: 160px;
  }

  #fifthImage {
    top: 330px;
    width: 130px;
  }

  #sixthImage {
    top: -5px;
    width: 200px;
    margin-left: 180px;
  }
}

@media screen and (min-width: 375px) and (max-width: 411px) {
  #firstImage {
    top: 520px;
    width: 170px;
  }

  #secondImage {
    top: 400px;
    width: 115px;
    margin-left: -25px;
  }

  #thirdImage {
    top: 460px;
    width: 180px;
    margin-left: -83px;
  }

  #fourthImage {
    top: 300px;
    margin-left: 5px;
    width: 140px;
  }

  #fifthImage {
    top: 310px;
    margin-left: 50px;
    width: 110px;
  }

  #sixthImage {
    position: absolute;
    top: -5px;
    width: 200px;
    margin-left: 90px;
  }
}

@media screen and (min-width: 220px) and (max-width: 374) {
}
