.theSofaProject {
  padding: 25px 25px 0px 25px;
}
.title h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

@media screen and (min-width: 667px) and (max-width: 1600px) {
  .sofaProjectPhotoAndDescription {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (min-width: 667px) and (max-width: 1600px) {
  .sofaProjectPhotoAndDescription .photo {
    width: 50%;
  }
  .sofaProjectDescription {
    width: 50%;
  }
}

@media screen and (min-width: 735px) and (max-width: 768px) {
  #playPhilosophy1 {
    width: 80%;
  }
  #playPhilosophy2 {
    width: 80%;
  }
}

@media screen and (min-width: 666px) and (max-width: 734px) {
  #playPhilosophy1 {
    width: 90%;
  }
  #playPhilosophy2 {
    width: 90%;
  }
  #playPhilosophy3 {
    width: 100%;
  }
  #performForObjects1 {
    width: 80%;
  }
  #performForObjects2 {
    width: 80%;
  }
}

@media screen and (min-width: 374px) and (max-width: 665px) {
  #playPhilosophy1 {
    width: 100%;
  }
  #playPhilosophy2 {
    width: 100%;
  }
  #playPhilosophy3 {
    width: 95%;
  }
}

@media screen and (min-width: 789px) and (max-width: 1600px) {
  .theSofaProject {
    padding: 25px 10px 0px 25px;
  }
}
