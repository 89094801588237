.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 50%;
  height: 45%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 4px 6px 5px 4px rgb(0 0 0 / 40%), 0px 1px 1px 0px rgb(0 0 0 / 28%),
    0px 1px 3px 0px rgb(0 0 0 / 24%);
}
.popup\_inner {
  position: absolute;
  margin: auto;
  border-radius: 20px;
  background: white;
}

.popupButton {
  display: flex;
  justify-content: center;
}
.popup button {
  margin-top: 20px;
  padding: 6px;
}

@media screen and (min-width: 737px) and (max-width: 768px) {
  .popupText {
    font-size: 16px;
  }
  .popup {
    height: 35%;
  }
}

@media screen and (min-width: 669px) and (max-width: 736px) {
  .popupText {
    font-size: 10px;
  }
}

@media screen and (min-width: 666px) and (max-width: 668px) {
  .popupText {
    font-size: 8px;
  }
  .popup {
    height: 55%;
  }
}

@media screen and (min-width: 374px) and (max-width: 665px) {
  .popupText {
    font-size: 10px;
  }
  .popup {
    width: 60%;
    height: 37%;
  }
}
