@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 50%;
  height: 45%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 4px 6px 5px 4px rgb(0 0 0 / 40%), 0px 1px 1px 0px rgb(0 0 0 / 28%),
    0px 1px 3px 0px rgb(0 0 0 / 24%);
}
.popup\_inner {
  position: absolute;
  margin: auto;
  border-radius: 20px;
  background: white;
}

.popupButton {
  display: flex;
  justify-content: center;
}
.popup button {
  margin-top: 20px;
  padding: 6px;
}

@media screen and (min-width: 737px) and (max-width: 768px) {
  .popupText {
    font-size: 16px;
  }
  .popup {
    height: 35%;
  }
}

@media screen and (min-width: 669px) and (max-width: 736px) {
  .popupText {
    font-size: 10px;
  }
}

@media screen and (min-width: 666px) and (max-width: 668px) {
  .popupText {
    font-size: 8px;
  }
  .popup {
    height: 55%;
  }
}

@media screen and (min-width: 374px) and (max-width: 665px) {
  .popupText {
    font-size: 10px;
  }
  .popup {
    width: 60%;
    height: 37%;
  }
}

* {
  margin: 0px;
  font-family: "Raleway", sans-serif;
}

.termsAndConditionsSheet .termsAndConditionsSheetPaper h2 {
  font-family: "Open Sans", sans-serif;
}

.termsAndConditionsSheet .termsAndConditionsSheetPaper p {
  font-family: "Open Sans", sans-serif;
}

.termsAndConditionsSheet .termsAndConditionsSheetPaper ol li {
  font-family: "Open Sans", sans-serif;
}

.termsAndConditionsSheet
  .termsAndConditionsSheetPaper
  .buttons
  .agreeButton
  span {
  font-family: "Open Sans", sans-serif;
}

.termsAndConditionsSheet
  .termsAndConditionsSheetPaper
  .buttons
  .disagreeButton
  span {
  font-family: "Open Sans", sans-serif;
}

.name {
  margin-left: 70px;
}

/* Sofa Logo Start*/
.termsAndConditions .termsAndConditionsPage #sofa {
  width: 40%;
  margin: 25px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .termsAndConditions .termsAndConditionsPage #sofa {
    width: 22%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .termsAndConditions .termsAndConditionsPage #sofa {
    width: 25%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .termsAndConditions .termsAndConditionsPage #sofa {
    width: 25%;
  }
}

@media screen and (min-width: 734px) and (max-width: 767px) {
  .termsAndConditions .termsAndConditionsPage #sofa {
    width: 25%;
  }
}

@media screen and (min-width: 684px) and (max-width: 733px) {
  .termsAndConditions .termsAndConditionsPage #sofa {
    width: 20%;
  }
}

/* Sofa Logo End */

/* Title and text start */

p {
  margin-top: 20px;
}

li {
  margin: 10px;
}

.termsAndConditionsSheet .termsAndConditionsSheetPaper .tc {
  margin: 10px 10px;
}

@media screen and (min-width: 1601px) and (max-width: 2400px) {
  .termsAndConditions .termsAndConditionsPage p {
    margin: 0px 470px;
  }
  .termsAndConditions .termsAndConditionsPage #sofa {
    width: 15%;
  }
  .termsAndConditionsSheet .termsAndConditionsSheetPaper ol li {
    margin: 0px 200px;
  }

  .termsAndConditionsSheet .termsAndConditionsSheetPaper .tc {
    margin: 10px 10px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .termsAndConditions .termsAndConditionsPage h1 {
    margin-top: 40px;
  }
  .termsAndConditions .termsAndConditionsPage p {
    margin: 0px 260px;
  }

  .termsAndConditionsSheet .termsAndConditionsSheetPaper ol li {
    margin: 0px 120px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .termsAndConditions .termsAndConditionsPage p {
    padding: 0px 60px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .termsAndConditions .termsAndConditionsPage p {
    padding: 0px 50px;
  }
}

@media screen and (min-width: 734px) and (max-width: 767px) {
  .termsAndConditions .termsAndConditionsPage p {
    padding: 0px 50px;
  }
}

@media screen and (min-width: 684px) and (max-width: 733px) {
  .termsAndConditions .termsAndConditionsPage p {
    padding: 0px 40px;
  }
}

@media screen and (min-width: 667px) and (max-width: 683px) {
  .termsAndConditions .termsAndConditionsPage p {
    padding: 0px 40px;
  }
}

/* Title and text end */

/* Terms and Conditions */
.termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
  box-shadow: 4px 6px 5px 4px rgb(0 0 0 / 40%), 0px 1px 1px 0px rgb(0 0 0 / 28%),
    0px 1px 3px 0px rgb(0 0 0 / 24%);
}

.termsAndConditions
  .termsAndConditionsPage
  .termsAndConditionsSheetPaper
  ol
  li {
  padding: 3px;
}

@media screen and (min-width: 1601px) and (max-width: 2400px) {
  .termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
    width: 50%;
    margin-left: 25%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
    width: 75%;
    margin-left: 15%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 734px) and (max-width: 767px) {
  .termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 684px) and (max-width: 733px) {
  .termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 667px) and (max-width: 683px) {
  .termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
    width: 80%;
    margin-left: 10%;
  }
}

.buttons {
  background-color: white;
}

.termsAndConditions
  .termsAndConditionsPage
  .termsAndConditionsSheet
  .termsAndConditionsSheetPaper
  #agreeButton {
  width: 70%;
}

.termsAndConditions
  .termsAndConditionsPage
  .termsAndConditionsSheet
  .termsAndConditionsSheetPaper
  #notAgreeButton {
  width: 70%;
}

@media screen and (min-width: 375px) and (max-width: 734px) {
  .popupText text {
    font-size: 8px;
  }
  .name {
    margin-left: 75px;
  }

  .termsAndConditionsSheet .termsAndConditionsSheetPaper h3 {
    padding: 20px;
  }

  .termsAndConditionsSheet .termsAndConditionsSheetPaper p {
    padding: 20px;
  }
}

#threeSofas {
  width: 40%;
  height: auto;
}

.photoAfterShow {
  width: 75%;
}

@media screen and (min-width: 666px) and (max-width: 1024px) {
  .photoAfterShow {
    width: 65%;
  }
}

@media screen and (min-width: 374px) and (max-width: 665px) {
  .photoAfterShow {
    width: 75%;
    margin-top: -40px;
  }
}

@media screen and (min-width: 789px) and (max-width: 1600px) {
  .sofaProjectPhotoAndDescription {
    padding: 20px 60px;
  }
}

.theSofaProject {
  padding: 25px 25px 0px 25px;
}
.title h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

@media screen and (min-width: 667px) and (max-width: 1600px) {
  .sofaProjectPhotoAndDescription {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (min-width: 667px) and (max-width: 1600px) {
  .sofaProjectPhotoAndDescription .photo {
    width: 50%;
  }
  .sofaProjectDescription {
    width: 50%;
  }
}

@media screen and (min-width: 735px) and (max-width: 768px) {
  #playPhilosophy1 {
    width: 80%;
  }
  #playPhilosophy2 {
    width: 80%;
  }
}

@media screen and (min-width: 666px) and (max-width: 734px) {
  #playPhilosophy1 {
    width: 90%;
  }
  #playPhilosophy2 {
    width: 90%;
  }
  #playPhilosophy3 {
    width: 100%;
  }
  #performForObjects1 {
    width: 80%;
  }
  #performForObjects2 {
    width: 80%;
  }
}

@media screen and (min-width: 374px) and (max-width: 665px) {
  #playPhilosophy1 {
    width: 100%;
  }
  #playPhilosophy2 {
    width: 100%;
  }
  #playPhilosophy3 {
    width: 95%;
  }
}

@media screen and (min-width: 789px) and (max-width: 1600px) {
  .theSofaProject {
    padding: 25px 10px 0px 25px;
  }
}

.contactDetails {
  margin-top: 20px;
}

h3 {
  margin-top: 15px;
}
p {
  margin-top: 5px;
}

@media screen and (min-width: 789px) and (max-width: 1600px) {
  .artistsList {
    display: flex;
    flex-direction: row;
    padding: 20px 60px;
  }

  .artistsList1 {
    width: 50%;
  }
  .artistsList .artistsList1 {
    width: 50%;
    margin-top: 10x;
  }
}

.description h1 {
  font-size: 16px;
}
/* Photo and description section start */
@media screen and (min-width: 667px) and (max-width: 2400px) {
  .photoAndDescription {
    display: flex;
    flex-direction: row;
  }
}

#soundtrackPhoto {
  width: 60%;
  margin-top: 40px;
} 

.description h1 {
  padding: 25px 5px 25px 25px;
}

@media screen and (min-width: 1601px) and (max-width: 2400px) {
  .photoAndDescription {
    margin-top: 40px;
  }

  .photo {
    width: 40%;
  }
  #soundtrackPhoto {
    width: 60%;
    margin-top: 0px;
  }
  .description {
    width: 60%;
    display: flex;
    align-items: center;
  }
  .description h1 {
    padding: 120px 5px 120px 120px;
  }
}

@media screen and (min-width: 667px) and (max-width: 1600px) {
  .photo {
    width: 40%;
  }
  #soundtrackPhoto {
    width: 65%;
    margin-top: 0px;
  }
  .description {
    width: 60%;
    display: flex;
    align-items: center;
  }
  .description h1 {
    padding: 45px 5px 45px 45px;
  }
}

@media screen and (min-width: 1601px) and (max-width: 2400px) {
  .photoAndDescription {
    margin-top: 40px;
  }
  #soundtrackPhoto {
    width: 55%;
  }
  .description h1 {
    margin: 0px 5px 0px 260px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .photoAndDescription {
    margin-top: 40px;
  }
  #soundtrackPhoto {
    width: 55%;
  }
  .description h1 {
    margin: 0px 5px 0px 120px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .photoAndDescription {
    margin-top: 40px;
  }
  .description p {
    padding: 60px 5px 60px 60px;
  }
}

@media screen and (min-width: 789px) and (max-width: 1600px) {
  .soundtracksPage {
    padding: 20px 60px;
  }
}

.tracks1 .track1 {
  background-color: #a4cfc5;
}

.tracks1 .track1:hover {
  background-color: #d95959;
  border-style: solid;
  border-width: 2px;
}

@media screen and (min-width: 1601px) and (max-width: 2400px) {
  .tracks1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .track1 {
    width: 40%;
  }
}

@media screen and (min-width: 734px) and (max-width: 1600px) {
  .tracks1 {
    display: flex;
    flex-direction: row;
  }

  .track1 {
    width: 50%;
  }
}

.reactPlayer {
  background-color: #f2f2f2;
  margin-right: -14px;
  display: flex;
  align-items: center;
  flex-direction: row; /* This property is updated by C++ code. */
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(230, 230, 230, 0.35);
  border-radius: 4px;
}

audio::-webkit-media-controls-enclosure {
  max-width: 270px;
}

audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
  font-family: "Hind Siliguri", sans-serif;
  color: #d95959;
}

/* Photo and description section start */
#workshopPhoto {
  width: 80%;
  margin-top: 40px;
}

@media screen and (min-width: 1601px) and (max-width: 2400px) {
  .workshopPhoto {
    width: 40%;
  }
  #workshopPhoto {
    width: 75%;
    margin-top: 40px;
  }
}

@media screen and (min-width: 667px) and (max-width: 1600px) {
  .workshopPhoto {
    /* width: 90%; */ 
  }
  #workshopPhoto {
    width: 60%;
    margin-top: 40px;
  }
}

@media screen and (min-width: 1601px) and (max-width: 2400px) {
  .workshopPhoto {
    margin-top: 40px;
  }
  #workshopPhoto {
    width: 55%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  #workshopPhoto {
    width: 55%;
  }
}

/* Photo and description section end */
#doors {
  width: 5%;
  margin-left: 1140px;
  margin-bottom: 40px;
  margin-top: -20px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #doors {
    width: 8%;
    margin-left: 860px;
  }
}

@media screen and (min-width: 734px) and (max-width: 768px) {
  #doors {
    width: 8%;
    margin-left: 620px;
  }
}

@media screen and (min-width: 667px) and (max-width: 733px) {
  #doors {
    width: 10%;
    margin-left: 560px;
  }
}

@media screen and (min-width: 667px) and (max-width: 683px) {
}

@media screen and (min-width: 411px) and (max-width: 414px) {
  #doors {
    margin-top: 30px;
    margin-left: 280px;
    width: 15%;
  }
}

@media screen and (min-width: 375px) and (max-width: 410px) {
  #doors {
    margin-top: 30px;
    margin-left: 260px;
    width: 15%;
  }
}

#blanket {
  /* position: absolute; */
  /* top: 440px; */
  margin-left: 0px;
  height: 120vh;
  width: 1300px;
  /*size: cover;*/
}

#firstImage {
  position: absolute;
  top: 440px;
  margin-left: 0px;
  width: 300px;
}

#secondImage {
  position: absolute;
  top: 320px;
  width: 220px;
  margin-left: 80px;
}

#thirdImage {
  position: absolute;
  top: 300px;
  width: 360px;
  margin-left: 70px;
}

#fourthImage {
  position: absolute;
  top: 220px;
  width: 280px;
  margin-left: 220px;
}

#fifthImage {
  position: absolute;
  top: 280px;
  margin-left: 110px;
  width: 180px;
}

#sixthImage {
  position: absolute;
  top: -10px;
  width: 320px;
  margin-left: 480px;
}

#aboutprojectImage {
  position: absolute;
  top: -130px;
  width: 340px;
}

/* RESPONSIVENESS */
@media screen and (min-width: 1601px) {
}

@media screen and (min-width: 1025px) and (max-width: 1600px) {
  #firstImage:hover {
    content: url(/static/media/workshops_titleResized.5035dbb3.png);
  }

  #secondImage:hover {
    content: url(/static/media/moreideastitle.2ecd876c.png);
  }

  #thirdImage:hover {
    content: url(/static/media/performancesforobjects_titleResized.622094ce.png);
  }

  #fourthImage:hover {
    content: url(/static/media/aboutthecreator_titleResized.60288216.png);
  }

  #fifthImage:hover {
    content: url(/static/media/soundtracksforsofas_titleResized.c8533127.png);
  }

  #sixthImage:hover {
    content: url(/static/media/contacttheartistscandeliertitle.e81caf8b.png);
  }
}

@media screen and (min-width: 220px) and (max-width: 1024px) {
  #firstImage {
    content: url(/static/media/workshops_titleResized.5035dbb3.png);
  }

  #secondImage {
    content: url(/static/media/moreideastitle.2ecd876c.png);
  }

  #thirdImage {
    content: url(/static/media/performancesforobjects_titleResized.622094ce.png);
  }

  #fourthImage {
    content: url(/static/media/aboutthecreator_titleResized.60288216.png);
  }

  #fifthImage {
    content: url(/static/media/soundtracksforsofas_titleResized.c8533127.png);
  }

  #sixthImage {
    content: url(/static/media/contacttheartistscandeliertitle.e81caf8b.png);
  }

  /* on hover */
  #firstImage:hover {
    content: url(/static/media/workshopsfinalResized.8c0eb628.png);
  }

  #secondImage:hover {
    content: url(/static/media/contacttheartistsfinal.3a0788e1.png);
  }

  #thirdImage:hover {
    content: url(/static/media/PerformancesforObjectsfinalResized.3b343232.png);
  }

  #fourthImage:hover {
    content: url(/static/media/aboutthecreatorfinalResized.76763fdf.png);
  }

  #fifthImage:hover {
    content: url(/static/media/Soundtrackforsofafinal.f5eee437.png);
  }

  #sixthImage:hover {
    content: url(/static/media/lampResized.49b81d9b.png);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #firstImage {
    width: 270px;
    top: 500px;
  }

  #secondImage {
    width: 190px;
    top: 400px;
  }

  #thirdImage {
    width: 330px;
    width: 270px;
    top: 400px;
  }

  #fourthImage {
    width: 230px;
    top: 260px;
  }

  #fifthImage {
    width: 150px;
  }

  #sixthImage {
    width: 280px;
    margin-left: 400px;
  }
}

@media screen and (min-height: 1024px) and (max-height: 1024px) {
    #fifthImage {
    top: 500px !important;
  }

  #fourthImage {
    top: 490px !important;
  }

  #thirdImage {
    top: 690px !important;
  }

  #secondImage {
    top: 600px !important;
  }

    #firstImage {
    top: 760px !important;
  }
}

@media screen and (min-height: 1366px) and (max-height: 1366px) {
  #firstImage {
    width: 370px;
    top: 1100px;
  }

  #secondImage {
    width: 290px;
    top: 890px;
    margin-left: 50px;
  }

  #thirdImage {
    width: 410px;
    top: 980px;
    margin-left: -60px;
  }

  #fourthImage {
    width: 330px;
    top: 630px;
    margin-left: 80px;
  }

  #fifthImage {
    width: 260px;
    top: 630px
  }

  #sixthImage {
    width: 380px;
    margin-left: 350px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #firstImage {
    top: 760px;
    margin-left: 20px;
    width: 240px;
  }

  #secondImage {
    top: 590px;
    width: 170px;
    margin-left: 60px;
  }

  #thirdImage {
    top: 660px;
    width: 240px;
    margin-left: 0px;
  }

  #fourthImage {
    top: 420px;
    width: 220px;
    margin-left: 80px;
  }

  #fifthImage {
    top: 450px;
    width: 160px;
    margin-left: 120px;
  }

  #sixthImage {
    top: 0px;
    width: 220px;
    margin-left: 300px;
  }
}

@media screen and (min-height: 736px) and (max-height: 736px) {
  #fifthImage {
    top: 350px !important;
  }

  #fourthImage {
    top: 360px !important;
  }

  #thirdImage {
    top: 560px !important;
  }

  #secondImage {
    top: 450px !important;
  }

    #firstImage {
    top: 610px !important;
  }
}

@media screen and (min-width: 734px) and (max-width: 767px) {
  #firstImage {
    top: 260px;
    margin-left: 20px;
    width: 200px;
  }

  #secondImage {
    top: 190px;
    width: 140px;
    margin-left: 60px;
  }

  #thirdImage {
    top: 200px;
    width: 170px;
  }

  #fourthImage {
    top: 110px;
    width: 180px;
    margin-left: 110px;
  }

  #fifthImage {
    top: 120px;
    width: 120px;
    margin-left: 80px;
  }

  #sixthImage {
    top: 0px;
    width: 140px;
    margin-left: 300px;
  }
}

@media screen and (min-width: 684px) and (max-width: 733px) {
  #firstImage {
    top: 280px;
    margin-left: 20px;
    width: 200px;
  }

  #secondImage {
    top: 200px;
    width: 140px;
    margin-left: 40px;
  }

  #thirdImage {
    top: 230px;
    width: 190px;
    margin-left: 40px;
  }

  #fourthImage {
    top: 110px;
    width: 170px;
    margin-left: 100px;
  }

  #fifthImage {
    top: 120px;
    width: 130px;
    margin-left: 60px;
  }

  #sixthImage {
    top: 0px;
    width: 140px;
    margin-left: 280px;
  }
}

@media screen and (min-width: 667px) and (max-width: 683px) {
  #firstImage {
    top: 230px;
    margin-left: 20px;
    width: 180px;
  }

  #secondImage {
    top: 160px;
    width: 130px;
    margin-left: 40px;
  }

  #thirdImage {
    top: 180px;
    width: 150px;
  }

  #fourthImage {
    top: 90px;
    width: 160px;
    margin-left: 80px;
  }

  #fifthImage {
    top: 90px;
    width: 120px;
    margin-left: 60px;
  }

  #sixthImage {
    top: 0px;
    width: 130px;
    margin-left: 280px;
  }
}

@media screen and (min-width: 412px) and (max-width: 666px) {
  #firstImage {
    top: 560px;
    width: 180px;
  }

  #secondImage {
    top: 410px;
    width: 135px;
    margin-left: 0px;
  }

  #thirdImage {
    top: 490px;
    width: 190px;
    margin-left: -60px;
  }

  #fourthImage {
    top: 310px;
    margin-left: 5px;
    width: 160px;
  }

  #fifthImage {
    top: 290px;
    margin-left: 70px;
    width: 130px;
  }

  #sixthImage {
    top: -5px;
    width: 200px;
    margin-left: 100px;
  }
}

@media screen and (min-height: 812px) and (max-height: 812px) {
  #firstImage {
    top: 680px !important;
  }

  #secondImage {
    top: 520px !important;
  }

  #thirdImage {
    top: 620px !important;
  }

  #fourthImage {
    top: 420px !important;
  }

  #fifthImage {
    top: 430px !important;
  }
}

@media screen and (min-height: 800px) and (max-height: 800px) {
    #fifthImage {
    top: 350px !important;
  }

  #fourthImage {
    top: 340px !important;
  }

  #thirdImage {
    top: 490px !important;
  }

  #secondImage {
    top: 450px !important;
  }

    #firstImage {
    top: 610px !important;
  }
}

@media screen and (min-height: 720px) and (max-height: 720px) {
  #firstImage {
    top: 560px;
    width: 180px;
  }

  #secondImage {
    top: 430px;
    width: 135px;
    margin-left: 40px;
  }

  #thirdImage {
    top: 500px;
    width: 190px;
    margin-left: -20px;
  }

  #fourthImage {
    top: 340px;
    margin-left: 20px;
    width: 160px;
  }

  #fifthImage {
    top: 330px;
    width: 130px;
  }

  #sixthImage {
    top: -5px;
    width: 200px;
    margin-left: 180px;
  }
}

@media screen and (min-width: 375px) and (max-width: 411px) {
  #firstImage {
    top: 520px;
    width: 170px;
  }

  #secondImage {
    top: 400px;
    width: 115px;
    margin-left: -25px;
  }

  #thirdImage {
    top: 460px;
    width: 180px;
    margin-left: -83px;
  }

  #fourthImage {
    top: 300px;
    margin-left: 5px;
    width: 140px;
  }

  #fifthImage {
    top: 310px;
    margin-left: 50px;
    width: 110px;
  }

  #sixthImage {
    position: absolute;
    top: -5px;
    width: 200px;
    margin-left: 90px;
  }
}

@media screen and (min-width: 220px) and (max-width: 374) {
}

