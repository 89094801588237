#threeSofas {
  width: 40%;
  height: auto;
}

.photoAfterShow {
  width: 75%;
}

@media screen and (min-width: 666px) and (max-width: 1024px) {
  .photoAfterShow {
    width: 65%;
  }
}

@media screen and (min-width: 374px) and (max-width: 665px) {
  .photoAfterShow {
    width: 75%;
    margin-top: -40px;
  }
}

@media screen and (min-width: 789px) and (max-width: 1600px) {
  .sofaProjectPhotoAndDescription {
    padding: 20px 60px;
  }
}
