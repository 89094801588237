h3 {
  margin-top: 15px;
}
p {
  margin-top: 5px;
}

@media screen and (min-width: 789px) and (max-width: 1600px) {
  .artistsList {
    display: flex;
    flex-direction: row;
    padding: 20px 60px;
  }

  .artistsList1 {
    width: 50%;
  }
  .artistsList .artistsList1 {
    width: 50%;
    margin-top: 10x;
  }
}
