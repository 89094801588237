@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
* {
  margin: 0px;
  font-family: "Raleway", sans-serif;
}

.termsAndConditionsSheet .termsAndConditionsSheetPaper h2 {
  font-family: "Open Sans", sans-serif;
}

.termsAndConditionsSheet .termsAndConditionsSheetPaper p {
  font-family: "Open Sans", sans-serif;
}

.termsAndConditionsSheet .termsAndConditionsSheetPaper ol li {
  font-family: "Open Sans", sans-serif;
}

.termsAndConditionsSheet
  .termsAndConditionsSheetPaper
  .buttons
  .agreeButton
  span {
  font-family: "Open Sans", sans-serif;
}

.termsAndConditionsSheet
  .termsAndConditionsSheetPaper
  .buttons
  .disagreeButton
  span {
  font-family: "Open Sans", sans-serif;
}

.name {
  margin-left: 70px;
}

/* Sofa Logo Start*/
.termsAndConditions .termsAndConditionsPage #sofa {
  width: 40%;
  margin: 25px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .termsAndConditions .termsAndConditionsPage #sofa {
    width: 22%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .termsAndConditions .termsAndConditionsPage #sofa {
    width: 25%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .termsAndConditions .termsAndConditionsPage #sofa {
    width: 25%;
  }
}

@media screen and (min-width: 734px) and (max-width: 767px) {
  .termsAndConditions .termsAndConditionsPage #sofa {
    width: 25%;
  }
}

@media screen and (min-width: 684px) and (max-width: 733px) {
  .termsAndConditions .termsAndConditionsPage #sofa {
    width: 20%;
  }
}

/* Sofa Logo End */

/* Title and text start */

p {
  margin-top: 20px;
}

li {
  margin: 10px;
}

.termsAndConditionsSheet .termsAndConditionsSheetPaper .tc {
  margin: 10px 10px;
}

@media screen and (min-width: 1601px) and (max-width: 2400px) {
  .termsAndConditions .termsAndConditionsPage p {
    margin: 0px 470px;
  }
  .termsAndConditions .termsAndConditionsPage #sofa {
    width: 15%;
  }
  .termsAndConditionsSheet .termsAndConditionsSheetPaper ol li {
    margin: 0px 200px;
  }

  .termsAndConditionsSheet .termsAndConditionsSheetPaper .tc {
    margin: 10px 10px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .termsAndConditions .termsAndConditionsPage h1 {
    margin-top: 40px;
  }
  .termsAndConditions .termsAndConditionsPage p {
    margin: 0px 260px;
  }

  .termsAndConditionsSheet .termsAndConditionsSheetPaper ol li {
    margin: 0px 120px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .termsAndConditions .termsAndConditionsPage p {
    padding: 0px 60px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .termsAndConditions .termsAndConditionsPage p {
    padding: 0px 50px;
  }
}

@media screen and (min-width: 734px) and (max-width: 767px) {
  .termsAndConditions .termsAndConditionsPage p {
    padding: 0px 50px;
  }
}

@media screen and (min-width: 684px) and (max-width: 733px) {
  .termsAndConditions .termsAndConditionsPage p {
    padding: 0px 40px;
  }
}

@media screen and (min-width: 667px) and (max-width: 683px) {
  .termsAndConditions .termsAndConditionsPage p {
    padding: 0px 40px;
  }
}

/* Title and text end */

/* Terms and Conditions */
.termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
  box-shadow: 4px 6px 5px 4px rgb(0 0 0 / 40%), 0px 1px 1px 0px rgb(0 0 0 / 28%),
    0px 1px 3px 0px rgb(0 0 0 / 24%);
}

.termsAndConditions
  .termsAndConditionsPage
  .termsAndConditionsSheetPaper
  ol
  li {
  padding: 3px;
}

@media screen and (min-width: 1601px) and (max-width: 2400px) {
  .termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
    width: 50%;
    margin-left: 25%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
    width: 75%;
    margin-left: 15%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 734px) and (max-width: 767px) {
  .termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 684px) and (max-width: 733px) {
  .termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 667px) and (max-width: 683px) {
  .termsAndConditions .termsAndConditionsPage .termsAndConditionsSheetPaper {
    width: 80%;
    margin-left: 10%;
  }
}

.buttons {
  background-color: white;
}

.termsAndConditions
  .termsAndConditionsPage
  .termsAndConditionsSheet
  .termsAndConditionsSheetPaper
  #agreeButton {
  width: 70%;
}

.termsAndConditions
  .termsAndConditionsPage
  .termsAndConditionsSheet
  .termsAndConditionsSheetPaper
  #notAgreeButton {
  width: 70%;
}

@media screen and (min-width: 375px) and (max-width: 734px) {
  .popupText text {
    font-size: 8px;
  }
  .name {
    margin-left: 75px;
  }

  .termsAndConditionsSheet .termsAndConditionsSheetPaper h3 {
    padding: 20px;
  }

  .termsAndConditionsSheet .termsAndConditionsSheetPaper p {
    padding: 20px;
  }
}
