.tracks1 .track1 {
  background-color: #a4cfc5;
}

.tracks1 .track1:hover {
  background-color: #d95959;
  border-style: solid;
  border-width: 2px;
}

@media screen and (min-width: 1601px) and (max-width: 2400px) {
  .tracks1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .track1 {
    width: 40%;
  }
}

@media screen and (min-width: 734px) and (max-width: 1600px) {
  .tracks1 {
    display: flex;
    flex-direction: row;
  }

  .track1 {
    width: 50%;
  }
}

.reactPlayer {
  background-color: #f2f2f2;
  margin-right: -14px;
  display: flex;
  align-items: center;
  flex-direction: row; /* This property is updated by C++ code. */
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(230, 230, 230, 0.35);
  border-radius: 4px;
}

audio::-webkit-media-controls-enclosure {
  max-width: 270px;
}

audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
  font-family: "Hind Siliguri", sans-serif;
  color: #d95959;
}
